import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, first, skip } from 'rxjs/operators';
import { NavigationComponent } from './navigation/navigation.component';

@Component({
    selector: 'public-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [NavigationComponent, RouterOutlet]
})
export class AppComponent implements OnInit {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: unknown,
        public elementRef: ElementRef,
        private router: Router
    ) {}

    public ngOnInit(): void {
        const setupHelpScoutBeacon = () => {
            // Handle
            if (!isPlatformBrowser(this.platformId)) {
                return;
            }

            const window = this.document.defaultView;

            const script = this.document.createElement('script');

            script.type = 'text/javascript';
            script.async = true;
            script.src = `https://beacon-v2.helpscout.net`;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (typeof (window as any).Beacon !== 'function') {
                const n = function (t, o, a) {
                    // eslint-disable-next-line prefer-rest-params
                    n['readyQueue'].push({
                        method: t,
                        options: o,
                        data: a
                    });
                };
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (n['readyQueue'] = []), ((window as any).Beacon = n);
            }

            this.document.body.append(script);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).Beacon('init', '63336b30-faed-4d8d-b33d-e0e1489e2f7e');
        };
        const setupHubspotTracking = () => {
            // Handle
            if (!isPlatformBrowser(this.platformId)) {
                return;
            }

            const window = this.document.defaultView;

            const script = this.document.createElement('script');

            script.type = 'text/javascript';
            script.id = 'hs-script-loader';
            script.async = true;
            script.defer = true;
            script.src = `https://js.hs-scripts.com/41543639.js`;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any)._hsq = (window as any)._hsq || [];

            this.document.body.append(script);
        };

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                first()
            )
            .subscribe({
                next: (_) => {
                    if (this.document.defaultView.requestIdleCallback != null) {
                        this.document.defaultView.requestIdleCallback(
                            () => {
                                setupHelpScoutBeacon();
                                setupHubspotTracking();
                            },
                            { timeout: 5000 }
                        );
                    } else {
                        setupHelpScoutBeacon();
                        setupHubspotTracking();
                    }
                }
            });

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                skip(1)
            )
            .subscribe({
                next: (_) => {
                    const window = this.document.defaultView;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (typeof (window as any).Beacon === 'function') {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (window as any).Beacon('event', {
                            type: 'page-viewed',
                            url: this.document.location.href,
                            title: this.document.title
                        });
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (window as any).Beacon('suggest');
                    }

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (typeof (window as any)._hsq !== 'undefined') {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (window as any)._hsq.push(['setPath', this.document.location.pathname + this.document.location.search]);
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (window as any)._hsq.push(['trackPageView']);
                    }
                }
            });
    }
}
