<div class="navigation flex h-[100px] w-full flex-col items-center justify-center">
    <div class="navigation-container flex w-full flex-row items-center justify-between p-[30px] tablet:p-[60px] web:max-w-[1500px]">
        <a routerLink="/" aria-label="MyMahi home page">
            <img class="logo h-8 mobile:h-6" alt="Logo" src="/assets/images/logos/mymahi/logo_mymahi.svg" />
        </a>
        @if (sizeWeb) {
            <div>
                <div class="flex flex-row items-center justify-start gap-8">
                    <div class="flex flex-row gap-8">
                        <a class="link-button" mat-button routerLink="/home">Home</a>
                        <a class="link-button" mat-button routerLink="/features">Features</a>
                        <a class="link-button" mat-button routerLink="/pricing">Pricing</a>
                        <a class="link-button" mat-button routerLink="/programmes">Programmes</a>
                        <a class="link-button" mat-button href="https://support.mymahi.com/" target="_blank" rel="noopener">Support</a>
                        <a class="link-button" mat-button (click)="displayContactUs()">Contact Us</a>
                    </div>
                    <div>
                        <a
                            class="link-button gradient"
                            mat-flat-button
                            color="green-gradient"
                            [href]="appLink"
                            target="_blank"
                            rel="noopener"
                            >Log in / Sign up</a
                        >
                    </div>
                </div>
            </div>
        }
        @if (!sizeWeb) {
            <div>
                <a mat-icon-button color="accent" [matMenuTriggerFor]="menu">
                    <mat-icon fontSet="fal" fontIcon="fa-bars" color="accent"></mat-icon>
                </a>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item routerLink="/home">Home</button>
                    <button mat-menu-item routerLink="/features">Features</button>
                    <button mat-menu-item routerLink="/pricing">Pricing</button>
                    <button mat-menu-item routerLink="/programmes">Programmes</button>
                    <a mat-menu-item href="https://support.mymahi.com/" target="_blank" rel="noopener">Support</a>
                    <button mat-menu-item (click)="displayContactUs()">Contact Us</button>
                    <a mat-menu-item [href]="appLink" target="_blank" rel="noopener">Log in / Sign up</a>
                </mat-menu>
            </div>
        }
    </div>
</div>
